import './App.css';
import {Canvas, useFrame} from '@react-three/fiber';
import {useGLTF, Merged, Float, BakeShadows, MeshReflectorMaterial, OrbitControls, Text} from '@react-three/drei';
import {createContext, useContext, useMemo, useRef} from 'react';

import {EffectComposer, Bloom, DepthOfField} from '@react-three/postprocessing';

function App() {
     const texts = 'COME BACK LATER\n\nNOVALDY IS BUSY';
     const context = createContext();
     return (
          <Canvas shadows dpr={[1, 1.5]} gl={{antialias: false, stencil: false}} camera={{position: [20, 0, 0], fov: 90}}>
               <ambientLight color="red" intensity={1.3} />
               <spotLight
                    angle={0}
                    color="red"
                    penumbra={0.5}
                    position={[10, 0, -10]}
                    intensity={40}
                    onUpdate={(self) => {
                         self.target.position.set(-10, 0, 0);
                         self.target.updateMatrixWorld();
                    }}
               />
               <pointLight position={[15, 30, 0]} lookAt={[0, -5.5, 0]} intensity={5000} />

               <MeshReflectorMaterial blur={[10, 5]} resolution={1080} mixBlur={0.1} mixStrength={80} roughness={1} depthScale={0} minDepthThreshold={0} maxDepthThreshold={0} color="#202020" metalness={0.8} />
               <SpaceInstance>
                    <SpaceWrapper rotation={[-1.5, 0.35, 1.1]} position={[0, -5, 0]} />
               </SpaceInstance>
               <BakeShadows />
               <Rig />
               <EffectComposer disableNormalPass>
                    <Bloom luminanceThreshold={0} mipmapBlur luminanceSmoothing={0.0} intensity={0.01} />
                    <DepthOfField target={[0, 0, 13]} focalLength={0.1} bokehScale={2} height={700} />
               </EffectComposer>
               <OrbitControls maxDistance={20} minDistance={20} minAzimuthAngle={Math.PI / 3} maxAzimuthAngle={Math.PI / 2} minPolarAngle={Math.PI / 3} maxPolarAngle={Math.PI / 2} />
          </Canvas>
     );

     function Rig() {
          useFrame((state) => {
               const {innerWidth, innerHeight} = window;
               const cameraX = innerWidth / 2;
               const cameraY = innerHeight / 2;

               state.camera.position.lerp({x: cameraX, y: cameraY, z: 0}, 0);
               state.camera.lookAt(0, 0, 0);
          });
     }

     function SpaceWrapper({...props}) {
          const wavesRef = useRef();
          const {nodes: n, materials: m} = useGLTF('/space_boi.glb');
          const instances = useContext(context);
          const scale = 6;

          const rotateWaves = () => {
               if (wavesRef.current) {
                    wavesRef.current.rotation.z += 0.003;
               }
          };
          useFrame(rotateWaves);
          console.log(m);
          return (
               <group {...props} dispose={null}>
                    {/* <instances.Sphere scale={scale / 2} /> */}
                    <Float>
                         <mesh castShadow receiveShadow geometry={n.Sphere001_Material002_0.geometry} scale={scale / 2} material={m['Material.002']} />
                         <mesh castShadow receiveShadow geometry={n.Sphere002_Material001_0.geometry} scale={scale / 2} material={m['Material.001']} />
                         <instances.Body1 scale={scale / 2} />
                         <instances.Body2 scale={scale / 2} />
                         <Text font="/mono.woff" textAlign="center" fontSize={1} rotation={[1.5, 0, 0]} position={[0, 0, -7]} color="white">
                              {texts}
                         </Text>
                    </Float>
                    <Float>
                         <instances.Particles scale={scale * 10} position={[100, -60, 100]} rotation={[1.5, 0, 0]} />
                    </Float>
                    <instances.Waves scale={scale} ref={wavesRef} />
               </group>
          );
     }

     function SpaceInstance({children, ...props}) {
          const {nodes} = useGLTF('/space_boi.glb');
          const instances = useMemo(
               () => ({
                    Cube: nodes.Cube_Material001_0,
                    Sphere: nodes.Sphere001_Material002_0,
                    Sphere1: nodes.Sphere002_Material001_0,
                    Sphere2: nodes.Sphere002_Material002_0,
                    Sphere3: nodes.Sphere003_Material002_0,
                    Sphere4: nodes.Sphere004_Material002_0,
                    Sphere5: nodes.Sphere005_Material001_0,
                    Sphere6: nodes.Sphere006_Material002_0,
                    Sphere7: nodes.Sphere007_Material001_0,
                    Sphere8: nodes.Sphere007_Material002_0,
                    Sphere9: nodes.Sphere008_Material002_0,
                    Sphere10: nodes.Sphere009_Material002_0,
                    Sphere11: nodes.Sphere010_Material002_0,
                    Sphere12: nodes.Sphere011_Material002_0,
                    Sphere13: nodes.Sphere_Material001_0,
                    Body1: nodes.body_Material001_0,
                    Body2: nodes.body_Material002_0,
                    Particles: nodes.particles_Material002_0,
                    Waves: nodes.waves_Material002_0,
                    Waves1: nodes.waves1_Material002_0,
                    Waves2: nodes.waves2_Material002_0
               }),
               [nodes]
          );
          console.log(nodes);
          return (
               <Merged castShadow recieveShadow meshes={instances} {...props}>
                    {(instances) => <context.Provider value={instances} children={children} />}
               </Merged>
          );
     }
}

export default App;
